import React, { useState } from 'react';

const MultiSelectDropdown = ({ list, onChange, clearText = '请选择', allowClear = true, icon = 'tag', value = [] }) => {
  // 状态管理：是否显示下拉菜单，和选中的项
  const [selectedItems, setSelectedItems] = useState(value || []);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (item) => {
    if (selectedItems.length === 0 && item === clearText) {
      return
    }
    let updatedItems = [...selectedItems];
    
    if (item === clearText) {
      updatedItems = updatedItems.length === 0 ? [clearText] : [];
    } else {
      if (updatedItems.includes(item)) {
        updatedItems = updatedItems.filter(i => i !== item);
      } else {
        updatedItems.push(item);
      }

      if (updatedItems.length > 0 && updatedItems.includes(clearText)) {
        updatedItems = updatedItems.filter(i => i !== clearText);
      }
    }

    setSelectedItems(updatedItems);
    onChange(updatedItems);  // 将选中的项传递给父组件
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);  // 切换下拉菜单的显示状态
  };

  return (
    <div className="multi-select-dropdown">
      <div className="dropdown-input" onClick={toggleDropdown}>
        <div className='value'>
          <i className={`vo2-${icon}`}></i>
          {!selectedItems.length && <span className="placeholder">{clearText}</span>}
          <span>
            {
              selectedItems
                .map(id => {
                  const selectedItem = list.find(item => item.id === id);
                  return selectedItem ? selectedItem.name : ''; 
                })
                .filter(item => item !== '')
                .join(', ')
            }
          </span>
        </div>
        <i className='icon-arrow-down'></i>
      </div>

      {/* 下拉菜单 */}
      {isDropdownOpen && (
        <div className="dropdown-menu">
          {allowClear && (
            <label className={selectedItems.length === 0 ? "selected" : ""}>
              <input
                type="checkbox"
                checked={selectedItems.length === 0}
                onChange={() => handleChange(clearText)}
              />
              <span></span>
              {clearText}
            </label>
          )}

          {/* 动态渲染选项 */}
          {list.map(({ name, id }) => (
            <label key={id} className={selectedItems.includes(id) ? "selected" : ""}>
              <input
                type="checkbox"
                checked={selectedItems.includes(id)}
                onChange={() => handleChange(id)}
              />
              <span></span>
              <p style={{margin: 0}}>{name}</p>
            </label>
          ))}
        </div>
      )}

      {isDropdownOpen && <div className='dropdown-invisiable-layer' onClick={() => {setIsDropdownOpen(false)}}></div>}
    </div>
  );
};

export default MultiSelectDropdown;