import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'
import { debounce } from 'lodash'
// import Helpers from '../../services/helpers.jsx'
// import { toast } from 'react-toastify'

class TagList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			sortKey: '',
			loading: true,
			filters: {
				name: ''
			},
			tabs: {
				links: [
					{ title: 'Project', path: '/projects' },
					{ title: 'Tag', path: '/tags' }
				]
			},
			tags: {
				columns: [{
					label: 'Name',
					key: 'name',
					order: true,
				}, {
					label: 'Updated',
					key: 'updatedAt',
					order: true,
					className: 'hide-line',
					view: function(field) {
						return moment(field).format('YYYY-MM-DD')
					},
					minWidth: '150px'
				}, {
					action: 'edit',
					editClicked: (id) => {
						this.props.history.push(`/tags/${id}/edit`)
					}
				}],
				entities: [],
				filters: [
					{
						label: 'TAG NAME',
						onChange: debounce(value => {
							if(value !== this.state.filters.name)
								this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
									this.loadEntities()
								})
						}, 500)
					}
				],
				actions: [{
					classList: 'yellow add',
					label: 'New tag',
					onClick: () => {
						this.props.history.push(`/tags/new`)
					}
				}],
				selectedItems: [],
				onRowClick: (entity) => {
					this.props.history.push('/tags/' + entity.id + '/edit')
				},
				onSort: (key) => {
					const formattedSortKey = this.state.sortKey.indexOf(key) > 0 ? key : this.state.sortKey.indexOf(key) === 0 ? `-${key}` : `-${key}`
					this.setState({ sortKey: formattedSortKey }, () => this.loadEntities())
				}
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		const params = {...this.state.filters, sort: this.state.sortKey}
		API.getTags(params).then(entities => this.setState({ ...this.state, tags: {...this.state.tags, entities: entities}, loading: false }))

	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={<>Manage<br />tags</>} tabs={this.state.tabs} />
				<Content>
					<Table settings={this.state.tags} />
				</Content>
			</>
	}
}

export default TagList;
