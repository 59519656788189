import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from './form.jsx'
import Modal from '../common/modal.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'
import API from '../../services/api.jsx'

class TagEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: {title: 'Back to Tag list', path: '/tags'},
			entity: null,
			isDeleteModalVisible: false,
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

  componentDidMount() {
    API.getTag(this.props.match.params.tagId).then(tag => this.setState({
      loading: false,
      entity: {...tag, new: false}
    }))
  }

	deleteEntity = () => {
		this.setState({ loading: true })
		API.delete('tags', this.props.match.params.tagId).then(() => {
			toast.success('Tag has been deleted')
			this.props.history.push('/tags')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

  saveEntity(fields) {
    this.setState({loading: true})
    const data = {
      name: fields.name
    }

		API.createOrUpdateTag(data, this.props.match.params.tagId).then(() => {
			toast.success('Tag has been updated')
			this.props.history.push('/tags')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}
	showDeleteModal = () => {
		this.setState({ isDeleteModalVisible: true });
	};

	closeDeleteModal = () => {
		this.setState({ isDeleteModalVisible: false });
	};

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head /*title={this.state.entity.name}*/ breadcrumb={this.state.breadcrumb}/>
				<Content>
					<Form new={false} cancel={() => this.props.history.push('/tags')} delete={this.showDeleteModal} save={this.saveEntity} entity={this.state.entity} popupVisible={this.state.popupVisible} togglePopup={() => this.setState({...this.state, popupVisible: !this.state.popupVisible})}/>
				</Content>
				<div className="actions under-content">
					<button onClick={() => {this.deleteEntity()}} className="red">Delete tag</button>
				</div>

				<Modal
					visible={this.state.isDeleteModalVisible}
					onClose={this.closeDeleteModal}
					title="Are you sure to delete this tag?"
					confirmText="Delete"
					cancelText="Cancel"
					onConfirm={() => {
						this.deleteEntity();
						this.closeDeleteModal();
					}}
					onCancel={this.closeDeleteModal}
					>
					<h4 className='remove-tag-notice'>
						This tag will be removed from {this.state.entity.projectCount} projects.
					</h4>
					<div className='tag-info'>{this.state.entity.name}</div>
				</Modal>
			</>
	}
}

export default TagEdit;
