import React from 'react'

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = props.entity
    this.state.new = props.new
    this.state.isInputed = false
  }

  updateField(e, key) {
    this.setState({[key]: e.target.value})
  }

  updateBoolean(value, key) {
    this.setState({[key]: value})
  }

  onCancel(e, callback) {
    e.preventDefault()
    callback()
  }

  onSubmit(e, callback) {
    e.preventDefault()
    callback()
  }

  handleDelete = () => {
    if (this.props.delete) {
      this.props.delete(this.state);
    }
  };

  handleSave = () => {
    if (this.props.save) {
      this.props.save(this.state);
    }
  };

  render() {
    return (
      <form className="tags" onSubmit={(e) => {
        e.preventDefault();
        this.handleSave();
      }}>
        {/* <div className="actions clearfix">
					<div className="buttons clearfix">
						<div className="button grey" onClick={(e) => this.onCancel(e, () => this.props.cancel())}>Cancel</div>
						<button className="blue">Save</button>
					</div>
				</div> */}

        <div className="block">
          <div className='header-window'>
            <h2>{this.state.new ? 'New' : 'Edit'} tag</h2>
            <div className='buttons'>
              {
                !this.state.new && (
                  <button type="button" className='delete-button' onClick={this.handleDelete}>Delete</button>
                )
              }
              <button type="button" onClick={this.handleSave} disabled={!this.state.name}>Save</button>
            </div>
          </div>

          <div className="fields clearfix name">
            <p style={{fontSize: '12px', marginLeft: '20px', fontWeight: 700, fontFamily: 'Jost', textTransform: 'uppercase'}}>Tag name</p>
            <div className="field">
              <input type="text"
                     style={{paddingLeft: '20px'}}
                     name="name"
                     onChange={(e) => this.setState({...this.state, name: e.target.value, isInputed: true})}
                     value={this.state.name || ''}/>
              {!this.state.name && this.state.isInputed && <span className='red' style={{paddingLeft: '20px'}}>Tag name required</span>}
            </div>
          </div>
        </div>
      </form>
    )
  }
}


export default Form;