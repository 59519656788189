import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'
import {debounce} from 'lodash'
import {toast} from 'react-toastify'

class PortfolioBuild extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sortKey: '',
      clients: [],
      loading: true,
      filters: {
        name: '',
        tags: [],
        year: []
      },
      tabs: {
        links: [
          {title: 'Create', path: '/portfolios/build'},
          {title: 'Manage', path: '/portfolios/templates'}
        ]
      },
      projects: {
        columns: [
          {
            label: 'Client Name',
            key: 'clientName',
            order: true,
            className: 'bold indent',
            minWidth: '200px'
          },
          {
            label: 'Project Name',
            key: 'name',
            order: true,
            minWidth: '200px'
          },
          {
            label: 'Tags',
            key: 'tags',
            view: function (tags) {
              let tag = tags.map(tag => <div key={tag.id} className="tag">{tag.name}</div>)
              return <div className="tags">
                {tag}
              </div>
            },
            minWidth: '200px'
          },
          {
            label: 'Year',
            key: 'year',
            order: true,
            className: 'bold',
            minWidth: '100px'
          },
          {
            label: 'Updated',
            key: 'updatedAt',
            order: true,
            view: function (field) {
              return moment(field).format('YYYY-MM-DD')
            },
            minWidth: '150px'
          }],
        entities: [],
        filters: [
          {
            label: 'SEARCH PROJECT',
            onChange: debounce(value => {
              if (value !== this.state.filters.name)
                this.setState({filters: {...this.state.filters, name: value}}, () => {
                  this.loadEntities()
                })
            }, 500)
          },
          {
            label: 'Year',
            type: 'multiSelect',
            value: null,
            entities: [],
            onChange: value => {
              this.setState({
                filters: {
                  ...this.state.filters,
                  year: value
                },
                projects: {
                  ...this.state.projects,
                  filters: [this.state.projects.filters[0], this.state.projects.filters[1], {
                    ...this.state.projects.filters[2],
                    value
                  }]
                }
              }, () => {
                this.loadEntities()
              })
            },
            clearText: 'ANYTIME',
            icon: 'calendar'
          },
          {
            label: 'All tags',
            type: 'multiSelect',
            value: null,
            entities: [],
            onChange: value => {
              const newFilters = {
                ...this.state.filters,
                tags: value
              }
              this.setState({
                filters: newFilters,
                projects: {
                  ...this.state.projects,
                  filters: [this.state.projects.filters[0], {
                    ...this.state.projects.filters[1],
                    value: value
                  }, this.state.projects.filters[2]]
                }
              }, () => {
                this.loadEntities()
              })
            },
            clearText: 'ALL TAGS',
            icon: 'tag'
          },
        ],
        actions: [
          {
            type: 'selectCheckboxes',
            onClick: () => {
              this.setState({
                ...this.state,
                projects: {
                  ...this.state.projects,
                  selectedItems: this.state.projects.entities.map(p => p.id),
                  actions: this.rebuildActions(this.state.projects.entities)
                }
              })
            }

          },
          {
            type: 'deselectCheckboxes',
            onClick: () => {
              this.setState({
                ...this.state,
                projects: {...this.state.projects, selectedItems: [], actions: this.rebuildActions([])}
              })
            }

          },
          {
            type: 'dropDown',
            classList: 'yellow dropdown-btn',
            label: `Save as`,
            menuProps: {
              items: [
                {
                  label: 'DECK',
                  key: 'deck'
                },
                {
                  label: 'PORTFOLIO',
                  key: 'portfolio'
                },
                {
                  label: 'NEW TEMPLATE',
                  key: 'newTemplate'
                }
              ],
              onClick: (e) => {
                const entitiesForPdf = [...this.state.projects.selectedItems]
                let selectOrNot = entitiesForPdf.length > 0
                if (selectOrNot) {
                  if (e.key === 'deck') {
                    API.createPortfolio(entitiesForPdf, 'deck').then(res => {
                      window.open(res)
                    })
                  } else if (e.key === 'portfolio') {
                    API.createPortfolio(entitiesForPdf, 'portfolio').then(res => {
                      window.open(res)
                    })
                  } else if (e.key === 'newTemplate') {
                    const selectedItems = [...this.state.projects.selectedItems];
                    this.props.history.push({
                      pathname: '/portfolios/templates/new',
                      state: {selectedItems}
                    });
                  }

                } else {
                  toast.error('You didn\'t select any projects')
                  this.setState({loading: false})
                }
              }
            }
          }
        ],
        selectedItems: [],
        multiSelect: true,
        isDraggable: true,
        onDragEnd: (param) => {
          const entities = [...this.state.projects.entities]
          if (param.destination) {
            const originalIndex = param.source.index
            const newIndex = param.destination.index
            entities.splice(newIndex, 0, entities.splice(originalIndex, 1)[0])

            const projects = this.state.projects
            projects.entities = entities
            this.setState({...this.state, projects: projects})

            // Update projects order
            let startIndex
            let endIndex
            if (originalIndex >= newIndex) {
              startIndex = newIndex
              endIndex = originalIndex
            } else {
              startIndex = originalIndex
              endIndex = newIndex
            }
            for (let i = startIndex; i <= endIndex; i++) {
              const data = {order: parseInt(i) + 1}

              API.updateProjectOrder(data, entities[i].id).then(() => {
                console.log('Update project order successfully')
              }).catch(() => {
                toast.error('An unexpected error has occured when updating projects order, please try again')
                this.setState({loading: false})
              })
            }
          }

        },
        onSelect: (key) => {
          const selectedItems = [...this.state.projects.selectedItems]
          const findIndex = selectedItems.indexOf(key)
          if (findIndex > -1) {
            selectedItems.splice(findIndex, 1);
          } else {
            selectedItems.push(key)
          }

          this.setState({
            ...this.state,
            projects: {...this.state.projects, selectedItems, actions: this.rebuildActions(selectedItems)}
          })
        },
        onSort: (key) => {
          const formattedSortKey = this.state.sortKey.indexOf(key) > 0 ? key : this.state.sortKey.indexOf(key) === 0 ? `-${key}` : `-${key}`
          this.setState({
            sortKey: formattedSortKey
          }, () => {
            this.loadEntities()
          })
        },
      }
    }
  }

  componentDidMount() {
    API.getTags().then(tags => {
      // create index
      tags = [].concat(tags)
      this.setState({
        tags: tags,
        projects: {
          ...this.state.projects,
          filters: [this.state.projects.filters[0], this.state.projects.filters[1], {
            ...this.state.projects.filters[2],
            entities: tags,
            value: tags[0]
          }]
        }
      }, () => {
        this.loadEntities()
      })
    })
  }

  rebuildActions(selectedItems) {
    const newActions = [...this.state.projects.actions]
    newActions[2].label = selectedItems.length === 0 ? 'Save as' : `Save as(${selectedItems.length})`
    return newActions
  }

  loadEntities() {
    API.getProjectsDetails(this.state.filters, this.state.sortKey).then(entities => {
      let yearFilter
      if (this.state.filters.year.length === 0) {
        const years = entities.filter(it => it.year !== undefined).map(it => {
          return {
            id: it.year,
            name: it.year
          }
        }).filter((item, index, self) => self.findIndex(t => t.id === item.id) === index).sort((a, b) => a.id - b.id)
        yearFilter = {
          ...this.state.projects.filters[1],
          entities: years,
          value: years[0]
        }
      } else {
        yearFilter = this.state.projects.filters[1]
      }
      this.setState({
        ...this.state,
        projects: {
          ...this.state.projects,
          filters: [this.state.projects.filters[0], yearFilter, this.state.projects.filters[2]],
          entities: entities
        },
        loading: false})
    })
  }

  render() {
    if (this.state.loading)
      return <Loader/>
    else
      return <>
        <Head title={<>Build<br/>portfolio</>} tabs={this.state.tabs}/>
        <Content>
          <Table settings={this.state.projects}/>
        </Content>
      </>
  }
}

export default PortfolioBuild;
