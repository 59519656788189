import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

const Modal = ({
  visible,
  onClose,
  title,
  children,
  customStyles = {},
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
}) => {
  return (
    <Rodal
      visible={visible}
      onClose={onClose}
      customStyles={{
        width: '400px',
        height: '200px',
        textAlign: 'center',
        paddingTop: '40px',
        ...customStyles,
      }}
    >
      {title && <h3>{title}</h3>}
      <div>{children}</div>
      <div className='modal-footer'>
        <button type='button' onClick={onCancel}>
          {cancelText}
        </button>
        <button type='button' onClick={onConfirm} className="red">
          {confirmText}
        </button>
      </div>
    </Rodal>
  );
};

export default Modal;