import React, {useEffect, useState} from 'react'
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import Table from '../../layout/table.jsx'
import moment from 'moment'
import Modal from './modal.jsx'
import {toast} from 'react-toastify'
import {debounce} from 'lodash'
import Form from "./form";

export default function PortFolioEdit(props) {
  const [portfolio, setPortfolio] = useState()
  const breadcrumb = {
    title: 'Back to templates', path: '/portfolios/templates'
  }

  useEffect(() => {
    API.read('portfolios', props.match.params.portfolioId).then(portfolio => {
      setPortfolio(portfolio)
    })
  }, [])
  return (
    <div style={{overflow: 'auto'}}>
      <Head breadcrumb={breadcrumb}/>
      <Content style={{overflow: 'auto'}}>
        <Form entity={portfolio}/>
      </Content>
    </div>
  )
}
