import React from 'react'
import Select from 'react-select'
import MultiSelectDropdown from '../common/multiSelectDropdown';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {DownOutlined} from '@ant-design/icons';
import {Dropdown, Space, Button} from 'antd';

class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItems: props.settings.selectedItems,
      order: {key: undefined},
      entities: props.settings.entities
    }
    this.onOrderClick = this.onOrderClick.bind(this)
  }

  makeCheckbox = (key) => {
    return (
      <label className="checkbox" style={{display: 'inline-flex', alignItems: 'center'}}>
        <span></span>
      </label>
    );
  };


  onRowClick = (entity, rowKey) => {
    const {selectedItems} = this.state;
    const selectedIndex = selectedItems.indexOf(entity.id);
    let newSelectedItems;

    if (selectedIndex > -1) {
      newSelectedItems = selectedItems.filter(item => item !== entity.id);
    } else {
      newSelectedItems = [...selectedItems, entity.id];
    }

    this.setState({selectedItems: newSelectedItems});

    if (this.props.settings.onSelect) {
      this.props.settings.onSelect(entity.id);
    }

    if (this.props.settings.onRowClick) {
      this.props.settings.onRowClick(entity);
    }
  };


  deleteRow(e) {
    e.preventDefault()
    e.stopPropagation()
    return true
  }

  onDragEnd = (props) => {
    // the only one that is required
    this.props.settings.onDragEnd(props)
  };

  onOrderClick = (e) => {
    if (this.props.settings.onSort) {
      this.props.settings.onSort(e);
    }
    this.setState({
      order: {
        key: e,
        direction: this.state.order.key === e && this.state.order.direction === 'desc' ? 'asc' : 'desc',
      },
    })

    // let copyItems = [...this.props.settings.entities]

    // if (this.state.order === undefined || this.state.order.key !== e) {
    //   copyItems.sort((a, b) => {
    //     const item1 = `${a[e]}`
    //     const item2 = `${b[e]}`
    //     const res = item1.localeCompare(item2);

    //     if (res > 0)
    //       return -1
    //     else if (res < 0)
    //       return 1

    //     return res;
    //   })

    //   this.setState({
    //     entities: copyItems,
    //     order: {
    //       key: e,
    //       direction: 'desc',
    //     },
    //   })
    // } else if (this.state.order.direction === 'desc') {
    //   copyItems.sort((a, b) => {
    //     const item1 = `${a[e]}`
    //     const item2 = `${b[e]}`
    //     const res = item1.localeCompare(item2);

    //     return res;
    //   })

    //   this.setState({
    //     entities: copyItems,
    //     order: {
    //       key: e,
    //       direction: 'asc',
    //     },
    //   })
    // } else {
    //   this.setState({
    //     entities: copyItems,
    //     order: {key: undefined},
    //   })
    // }
  }

  render() {
    return (
      <>
        <div className="actions clearfix">
          <div className="filters clearfix">
            {
              this.props.settings.filters.map((action, i) => {
                if (action.type === 'select')
                  return <div key={`field-${i}`} className="search select">
                    <Select
                      value={action.value}
                      onChange={action.onChange}
                      options={action.entities}
                      getOptionLabel={entity => entity.name}
                      getOptionValue={entity => entity.id}
                    />
                  </div>
                else if (action.type === 'multiSelect') {
                  return <div key={`field-${i}`} className="search select">
                    <MultiSelectDropdown list={action.entities} onChange={action.onChange} clearText={action.clearText}
                                         icon={action.icon} value={[]}/>
                  </div>
                } else {
                  return <div key={`field-${i}`} className="search icon-bigsearch">
                    <input type="text" placeholder={action.label} onChange={(e) => action.onChange(e.target.value)}/>
                  </div>
                }
              })
            }
            {
              this.props.settings.actions.map((action, i) => {
                if (action.type === 'deselectCheckboxes') {
                  return (
                    <div key={`field-${i}`} className="deselect" style={action.style} onClick={() => action.onClick()}>
                      Deselect all
                    </div>
                  )
                } else if (action.type === 'selectCheckboxes') {
                  return (
                    <div key={`field-${i}`} className="deselect" style={action.style} onClick={() => action.onClick()}>
                      Select all
                    </div>
                  )
                } else {
                  return null
                }
              })
            }
          </div>

          <div className="buttons clearfix">
            {
              this.props.settings.actions.map((action, i) => {
                if (action.type === 'deselectCheckboxes' || action.type === 'selectCheckboxes') {
                  return null
                } else if (action.type === 'dropDown') {
                  return (
                    <Dropdown key={`dropdown-${i}`} menu={action.menuProps}>
                      <Button className={action.classList}>
                        <Space>
                          {action.label}
                          <DownOutlined className='dropdown-icon'/>
                        </Space>
                      </Button>
                    </Dropdown>
                  )
                } else {
                  return (
                    <button
                      key={`button-${i}`}
                      className={action.classList}
                      onClick={action.onClick}
                    >
                      {action.label}
                    </button>
                  )
                }
              })
            }
          </div>
        </div>

        <div className="table-wrapper">
          {
            this.props.settings.entities && <table className={this.props.settings.isDraggable ? 'draggable' : ''}>
              <thead style={{position: 'sticky', top: '0', background: '#fff', zIndex: 2}}>
              <tr>
                {
                  this.props.settings.columns.map((column, key) => {
                    return (
                      <td
                        key={key}
                        onClick={() => column.order && this.onOrderClick(column.key)}
                        // style={{
                        // 	cursor: column.order && 'pointer',
                        // 	color: this.state.order.key === column.key && '#2196F3',
                        // }}
                        className={
                          `${column.order ? 'sortable' : ''}${this.state.order.key === column.key ? ' active' : ''}${column.className ? ' ' + column.className : ''}`
                        }
                        style={column.minWidth ? {minWidth: column.minWidth, width: 'initial'} : {}}
                      >
                        {column.label}
                        {column.order &&
                          <svg
                            onClick={() => this.onOrderClick(column.key)}
                            xmlns="http://www.w3.org/2000/svg"
                            width={8}
                            height={6}
                            fill="none"
                            style={{
                              marginLeft: 15,
                              marginBottom: 2,
                              transform:
                                this.state.order.key === column.key &&
                                this.state.order.direction === 'desc' ? 'rotate(180deg)' : undefined,
                              transition: 'transform 0.3s',
                            }}
                          >
                            <path
                              fill='#1E183A'
                              d="m4 5.266-4-4L.717.55 4 3.833 7.283.55 8 1.266l-4 4Z"
                            />
                          </svg>

                          // <span
                          // 	onClick={() => this.onOrderClick(column.key)} style={{
                          // 		float: 'right',
                          // 		cursor: 'pointer',
                          // 		color: this.state.order.key === column.key ? 'blue' : undefined
                          // 	}}
                          // >
                          // 	{this.state.order.direction === 'desc' ? 'A' : 'V'}
                          // </span>
                        }
                      </td>
                    )
                  })
                }
                {/* {
										this.props.settings.isDraggable &&
										<td></td>
									} */}
              </tr>
              </thead>

              <DragDropContext
                onDragEnd={(param) => this.props.settings.onDragEnd(param)}
              >
                <Droppable droppableId={'droppable-area'}>
                  {(provided, _) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps} >
                    {
                      this.props.settings.entities.map((entity, rowKey) => (
                        <Draggable
                          draggableId={entity.id + 'draggable'}
                          key={entity.id + 'draggable'}
                          index={rowKey}
                          isDragDisabled={true}
                        >
                          {(provided, snapshot) => (
                            <tr
                              key={entity.id}
                              onClick={() => this.onRowClick(entity, rowKey)}
                              style={{
                                background: snapshot.isDragging ? '#EAEAFF' : '#0000'
                              }}
                              ref={provided.innerRef}
                              className={this.props.settings.selectedItems && this.props.settings.selectedItems.includes(entity.id) ? 'selected' : ''}
                              {...provided.draggableStyle}
                              {...provided.draggableProps}
                            >
                              {
                                this.props.settings.columns.map((column, key) => {
                                  if (column.action === 'delete') {
                                    return (
                                      <td
                                        className={(column.className ? column.className : '')}
                                        key={entity.id + 'delete'}
                                        style={{width: '5%'}}
                                      >
                                        <i
                                          className="icon-trash"
                                          onClick={(e) =>
                                            this.deleteRow(e, entity.id, rowKey) && column.onClick && column.onClick(entity.id, rowKey)
                                          }
                                        ></i>
                                      </td>
                                    )
                                  }
                                  else if (column.action === 'edit') {
                                    return (
                                      <td
                                        className={(column.className ? column.className : '')}
                                        key={entity.id + 'edit'}
                                        style={{width: '5%'}}
                                      >
                                        <i
                                          className="vo2-editing"
                                          onClick={(e) => column.editClicked(entity.id)}
                                        ></i>
                                      </td>
                                    )
                                  }
                                  else if (column.action === 'download') {
                                    return (
                                      <td
                                        className={(column.className ? column.className : '')}
                                        key={entity.id + 'download'}
                                        style={{width: '5%'}}
                                      >
                                        <i
                                          className="icon-downloading"
                                          onClick={(e) => column.handleDownload(entity.projects)}
                                        ></i>
                                      </td>
                                    )
                                  }
                                  else if (column.action === 'dropDown') {
                                    return (
                                      <td key={entity.id + 'dropDown'} onMouseOver={e => column.handleMouseOver(entity.projects)}>
                                        <Dropdown key={`dropdown-${key}`} menu={column.menuProps}>
                                          <Button className={column.classList}>
                                            <Space>
                                              <i className={column.icon} style={{color: 'black'}}></i>
                                            </Space>
                                          </Button>
                                        </Dropdown>
                                      </td>
                                    )
                                  } else
                                    return (
                                      <td
                                        className={(column.className ? column.className : '')}
                                        key={entity.id + key}
                                      >
                                        <div style={{display: 'flex'}}>
                                          {this.props.settings.multiSelect && key === 0 && this.makeCheckbox(entity.id)}
                                          <p>{column.view ? column.view(entity[column.key], entity) : entity[column.key]}</p>
                                        </div>
                                      </td>
                                    )
                                })
                              }
                            </tr>
                          )}
                        </Draggable>
                      ))
                    }
                    {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          }
          {(!this.props.settings.entities || !this.props.settings.entities.length) &&
            <div className="empty">There is no content in this list</div>}
        </div>
      </>
    );
  }

}


export default Table;

