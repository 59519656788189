import Head from "../../layout/head";
import Content from "../../layout/content";
import Form from "./form";

export default function NewPortfolio() {
  const breadcrumb = {
    title: 'Back to templates', path: '/portfolios/templates'
  }
  return (
    <div style={{overflow: 'auto'}}>
      <Head breadcrumb={breadcrumb}/>
      <Content style={{overflow: 'auto'}}>
        <Form/>
      </Content>
    </div>
  )
}