import { useLocation, useHistory } from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import Table from "../../layout/table";
import Modal from "../../common/modal";
import moment from "moment/moment";
import {debounce} from "lodash";
import API from "../../../services/api";
import {toast} from "react-toastify";

export default function Form(props) {
  const entity = props.entity;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [name, setName] = useState('')
  const [sortKey, setSortKey] = useState('')
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    name: undefined,
    tags: [],
    year: [],
  });
  const location = useLocation();
  let history = useHistory();
  const initialEntitiesRef = useRef([])
  const [projects, setProjects] = useState({
    columns: [
      {
        label: 'Client Name',
        key: 'clientName',
        order: true,
        className: 'bold',
        minWidth: '151px'
      },
      {
        label: 'Project Name',
        key: 'name',
        order: true,
        className: 'bold',
      },
      {
        label: 'Tags',
        key: 'tags',
        view: function (tags) {
          const tag = tags.map(tag => <div key={tag.id} className="tag">{tag.name}</div>)
          return <div className="tags">
            {tag}
          </div>
        },
      },
      {
        label: 'Year',
        key: 'year',
        order: true,
        minWidth: '100px'
      },
      {
        label: 'Updated',
        key: 'updatedAt',
        order: true,
        view: function (field) {
          return moment(field).format('YYYY-MM-DD')
        },
        minWidth: '140px'
      }],
    entities: [],
    filters: [
      {
        label: 'SEARCH PROJECT',
        onChange: debounce(value => {
          setFilters(prevState => {
            return {
              ...prevState,
              name: value
            }
          })
        }, 500)
      },
      {
        label: 'Year',
        type: 'multiSelect',
        value: null,
        entities: [],
        clearText: 'ANY TIME',
        onChange: value => {
          setFilters(prevState => {
            return {
              ...prevState,
              year: value
            }
          })
          const newFilters = [...projects.filters]
          newFilters[1].value = value
          setProjects(prevState => {
            return {
              ...prevState,
              filters: newFilters
            }
          })
        },
        icon: 'calendar'
      },
      {
        label: 'All tags',
        type: 'multiSelect',
        value: null,
        entities: [],
        onChange: value => {
          setFilters(prevState => {
            return {
              ...prevState,
              tags: value
            }
          })
        },
        clearText: 'ALL TAGS'
      },
    ],
    actions: [
      {
        type: 'selectCheckboxes',
        style: {color: '#000'},
        onClick: () => {
          setProjects(prevState => {
            return {
              ...prevState,
              selectedItems: prevState.entities.map(it => it.id)
            }
          })
        }

      },
      {
        type: 'deselectCheckboxes',
        style: {color: '#000'},
        onClick: () => {
          setProjects(prevState => {
            return {
              ...prevState,
              selectedItems: []
            }
          })
        }

      },
    ],
    selectedItems: location.state?.selectedItems || [],
    multiSelect: true,
    isDraggable: false,
    onSelect: (key) => {
      setProjects((prevProjects) => {
        const selectedItems = [...prevProjects.selectedItems];
        const findIndex = selectedItems.indexOf(key);
        if (findIndex > -1) {
          selectedItems.splice(findIndex, 1);
        } else {
          selectedItems.push(key);
        }

        return {
          ...prevProjects,
          selectedItems,
        };
      });
    },
    onSort: (key) => {
      setSortKey((prevSortKey) => {
        const newSortKey = prevSortKey.indexOf(key) > 0 ? key : prevSortKey.indexOf(key) === 0 ? `-${key}` : `-${key}`
        return newSortKey;
      });
    },
  })

  useEffect(() => {
    if (entity) {
      setName(entity.name);
      setProjects(prevState => {
        return {
          ...prevState,
          selectedItems: entity.projects
        }
      })
    }
  }, [entity])

  useEffect(() => {
    API.getProjectsDetails(filters, sortKey).then(entities => {
      setProjects((prevProjects) => ({
        ...prevProjects,
        entities,
      }));
      setLoading(false)
      if (initialEntitiesRef.current.length === 0) {
        initialEntitiesRef.current = entities
      }
      const years = initialEntitiesRef.current.filter(it => it.year !== undefined).map(it => {
        return {
          id: it.year,
          name: it.year
        }
      }).filter((item, index, self) => self.findIndex(t => t.id === item.id) === index).sort((a, b) => a.id - b.id)
      const yearFilter = {
        ...projects.filters[1],
        entities: years,
        value: years[0]
      }
      const newFilters = [...projects.filters]
      newFilters[1] = yearFilter
      setProjects((prevProjects) => ({
        ...prevProjects,
        filters: newFilters
      }));
    })
  }, [filters, sortKey]);

  useEffect(() => {
    API.getProjectsDetails(filters, sortKey).then(entities => {
      setProjects((prevProjects) => {
        const years = entities.filter(it => it.year !== undefined).map(it => ({
          id: it.year,
          name: it.year
        })).filter((item, index, self) => self.findIndex(t => t.id === item.id) === index).sort((a, b) => a.id - b.id);
        
        const newFilters = [...prevProjects.filters];
        newFilters[1] = {
          ...newFilters[1],
          entities: years,  
          value: years[0],  
        };
        
        return {
          ...prevProjects,
          filters: newFilters,  
        };
      });
  
      setLoading(false);
      if (initialEntitiesRef.current.length === 0) {
        initialEntitiesRef.current = entities;
      }
    });
  }, []);

  useEffect(() => {
    API.getTags().then(tags => {
      tags = [].concat(tags)
      const newFilters = [...projects.filters]
      newFilters[2].entities = tags
      newFilters[2].value = tags[0]
      setProjects(prevState => {
        return {
          ...prevState,
          tags,
          filters: newFilters
        }
      })
    })
  }, [])

  const onClickSave = async () => {
    await API.createOrUpdatePortfolio({
      name,
      projects: projects.selectedItems,
      projectsOrder: projects.selectedItems
    }, entity ? entity.id : null)
    toast.success(`Template has been ${entity ? 'updated' : 'saved'}`)
    history.push('/portfolios/templates')
  }

  const handleDelete = async () => {
    await API.delete('portfolios', entity.id).catch(error => {
        console.log('error', error)
    })
    setIsDeleteModalVisible(false)
    history.push('/portfolios/templates')
  }
  return (
    <div className="create-template-page" style={{backgroundColor: 'white', padding: '40px', overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
      <div className="header-window" style={{height: '40px'}}>
        <h2>{entity ? 'EDIT' : 'CREATE'} TEMPLATE</h2>
        <div className='buttons'>
          <button className="delete-button" onClick={()=> {setIsDeleteModalVisible(true)}}>DELETE</button>
          <button onClick={onClickSave}>SAVE</button>
        </div>
      </div>
      <div style={{flex: 1, overflow: 'auto'}}>
        <p className='template-subtitle'>template information</p>
        <div className='template-field'>
          <label>
            <p className='field-title'>Template Name</p>
            <input value={name} onChange={e => setName(e.target.value)} placeholder="Template Name"/>
          </label>
        </div>
        <div className='template-field project-quantity'>
          <p className='field-title'>Contained Projects</p>
          <label>
            <input className='quantity' disabled value={projects.selectedItems.length} onChange/>
          </label>
        </div>
        <p className='note'>*Select projects from the list below to add into this template.</p>
        <div>
          {
            loading ? <></> : <Table settings={projects}/>
          }
        </div>
      </div>
      <Modal
        visible={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        title="Are you sure to delete this template?"
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleDelete}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
      </Modal>
    </div>
  )
}