import React from 'react'
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import Table from '../../layout/table.jsx'
import Modal from './modal.jsx'
import moment from 'moment'
import {toast} from 'react-toastify'
import {debounce} from 'lodash'

class PortfolioList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sortKey: '',
      clients: [],
      loading: true,
      entity: {
        name: null,
        projects: []
      },
      filters: {
        name: ''
      },
      tabs: {
        links: [
          {title: 'Create', path: '/portfolios/build'},
          {title: 'Manage', path: '/portfolios/templates'}
        ]
      },
      portfolios: {
        columns: [{
          label: 'Name',
          key: 'name',
          order: true,
        }, {
          label: 'Updated at',
          key: 'updatedAt',
          order: true,
          className: 'bold hide-line',
          view: function (field) {
            return moment(field).format('YYYY-MM-DD')
          }
        },{
          action: 'dropDown',
          className: 'hide-line',
          classList: 'dropdown-btn',
          icon: 'vo2-download',
          menuProps: {
            items: [
              {
                label: 'SAVE AS DECK',
                key: 'deck'
              },
              {
                label: 'SAVE AS PDF',
                key: 'portfolio'
              }
            ],
            onClick: (e) => {
              const entitiesForPdf = [...this.state.entity.projects]
              if (e.key === 'deck') {
                API.createPortfolio(entitiesForPdf, 'deck').then(res => {
                  window.open(res)
                })
              } else if (e.key === 'portfolio') {
                API.createPortfolio(entitiesForPdf, 'portfolio').then(res => {
                  window.open(res)
                })
              }
            }
          },
          handleMouseOver: (projects) => {
            this.setState({
              ...this.state,
              entity: {
                ...this.state.entity,
                projects
              }
            })
          }
        }, {
          action: 'edit',
          editClicked: portfolioId => {
            this.props.history.push(`/portfolios/templates/${portfolioId}/edit`)
          }
        }],
        entities: [],
        filters: [
          {
            label: 'TEMPLATE NAME',
            onChange: debounce(value => {
              if (value !== this.state.filters.name)
                this.setState({filters: {...this.state.filters, name: value ? '/' + value + '/i' : ''}}, () => {
                  this.loadEntities()
                })
            }, 500)
          },
        ],
        actions: [
          {
            classList: 'yellow add',
            label: 'Create new template',
            onClick: () => {
              this.props.history.push('/portfolios/templates/new')
            }
          }
        ],
        selectedItems: [],
        onSort: (key) => {
          const formattedSortKey = this.state.sortKey.indexOf(key) > 0 ? key : this.state.sortKey.indexOf(key) === 0 ? `-${key}` : `-${key}`
					this.setState({ sortKey: formattedSortKey }, () => this.loadEntities())
				},
      }
    }
  }

  componentDidMount() {
    this.loadEntities()
  }

  loadEntities() {
		const params = {...this.state.filters, sort: this.state.sortKey}
    API.getPortfolios(params).then(entities => {
      this.setState({...this.state, portfolios: {...this.state.portfolios, entities}, loading: false})
    })
  }

  save = () => {
    if (!this.state.entity.name) {
      toast.error('Please enter a name')
    }

    API.create('portfolios', this.state.entity).then(() => {
      this.setState({...this.state, popupVisible: false})
      this.loadEntities()
      toast.success('Portfolio successfully created')
    }).catch(err => {
      toast.success('There was a problem creating this portfolio')
    })
  }

  render() {
    if (this.state.loading)
      return <Loader/>
    else
      return <>
        <Head title={<>Manage<br/>portfolio</>} tabs={this.state.tabs}/>
        <Content>
          <Table settings={this.state.portfolios}/>
          <Modal
            create
            entity={this.state.entity}
            popupVisible={this.state.popupVisible}
            closePopup={() => this.setState({...this.state, popupVisible: false})}
            updateName={(e) => this.setState({...this.state, entity: {...this.state.entity, name: e.target.value}})}
            save={this.save}
          />
        </Content>
      </>
  }
}

export default PortfolioList;
