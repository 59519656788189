import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'
import {debounce} from 'lodash'

class ProjectsList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sortKey: '',
      clients: [],
      loading: true,
      filters: {
        name: '',
        clientName: '',
        year: [],
        tags: []
      },
      tabs: {
        links: [
          {title: 'Project', path: '/projects'},
          {title: 'Tag', path: '/tags'}
        ]
      },
      projects: {
        columns: [
          {
            label: 'Client Name',
            key: 'clientName',
            order: true,
            className: 'bold',
            minWidth: '160px'
          },
          {
            label: 'Project Name',
            key: 'name',
            order: true,
            className: 'bold',
            minWidth: '160px'
          },
          {
            label: 'Tags',
            key: 'tags',
            view: function (tags) {
              let tag = tags.map(tag => <div key={tag.id} className="tag">{tag.name}</div>)
              return <div className="tags">
                {tag}
              </div>
            }
          },
          {
            label: 'Year',
            key: 'year',
            order: true,
            className: 'bold',
            minWidth: '120px'
          },
          {
            label: 'Updated Date',
            key: 'updatedAt',
            order: true,
            className: 'hide-line',
            view: function (field) {
              return moment(field).format('YYYY-MM-DD')
            },
            minWidth: '160px'
          },
          {
            action: 'edit',
            editClicked: (id) => {
              this.props.history.push(`/projects/${id}/edit`)
            }
          }
        ],
        entities: [],
        filters: [
          {
            label: 'SEARCH PROJECT',
            onChange: debounce(value => {
              if (value !== this.state.filters.name)
                this.setState({
                  filters: {
                    ...this.state.filters,
                    name: value
                  }
                }, () => {
                  this.loadEntities()
                })
            }, 500)
          },
          {
            label: 'Year',
            type: 'multiSelect',
            value: null,
            entities: [],
            clearText: 'ANYTIME',
            icon: 'calendar',
            onChange: value => {
              this.setState({
                filters: {
                  ...this.state.filters,
                  year: value
                },
                projects: {
                  ...this.state.projects,
                  filters: [
                    this.state.projects.filters[0],
                    {
                      ...this.state.projects.filters[1],
                      value
                    },
                    this.state.projects.filters[2],
                  ]
                }
              }, () => {
                this.loadEntities()
              })
            }
          },
          {
            label: 'Search for a tag',
            type: 'multiSelect',
            value: null,
            entities: [],
            onChange: value => {
              const newFilters = {
                ...this.state.filters,
                tags: value
              }
              this.setState({
                filters: newFilters,
                projects: {
                  ...this.state.projects,
                  filters: [this.state.projects.filters[0], {...this.state.projects.filters[1], value: value}, this.state.projects.filters[2]]
                }
              }, () => {
                this.loadEntities()
              })
            },
            clearText: 'ALL TAGS'
          },
        ],
        actions: [{
          classList: 'yellow add',
          label: 'Add new project',
          onClick: () => {
            this.props.history.push(`/projects/new`)
          }
        }],
        selectedItems: [],
        onRowClick: (entity) => {
          this.props.history.push('/projects/' + entity.id + '/edit')
        },
        onSort: (key) => {
          const formattedSortKey = this.state.sortKey.indexOf(key) > 0 ? key : this.state.sortKey.indexOf(key) === 0 ? `-${key}` : `-${key}`
          this.setState({
            sortKey: formattedSortKey
          }, () => {
            this.loadEntities()  
          })
        },
      }
    }
  }

  componentDidMount() {
    API.getTags().then(tags => {
      tags = [].concat(tags)
      this.setState({
        ...this.state,
        projects: {
          ...this.state.projects,
          filters: [this.state.projects.filters[0], this.state.projects.filters[1], {
            ...this.state.projects.filters[2],
            entities: tags,
            value: tags[0]
          }]
        }
      }, () => {
        this.loadEntities()
      })

    })
  }

  loadEntities() {
    API.getProjectsDetails(this.state.filters, this.state.sortKey).then(entities => {
      let yearFilter
      if (this.state.filters.year.length === 0) {
        const years = entities.filter(it => it.year !== undefined).map(it => {
          return {
            id: it.year,
            name: it.year
          }
        }).filter((item, index, self) => self.findIndex(t => t.id === item.id) === index).sort((a, b) => a.id - b.id)
        yearFilter = {
          ...this.state.projects.filters[1],
          entities: years,
          value: years[0]
        }
      } else {
        yearFilter = this.state.projects.filters[1]
      }
      this.setState(prevState => ({
        projects: {
          ...prevState.projects,
          entities: [...entities],
          filters: [this.state.projects.filters[0], yearFilter, this.state.projects.filters[2]]
        },
        loading: false
      }))
    })
  }

  render() {
    if (this.state.loading)
      return <Loader/>
    else
      return <>
        <Head title={<>Manage<br/>projects</>} tabs={this.state.tabs}/>
        <Content>
          <Table settings={this.state.projects}/>
        </Content>
      </>
  }
}

export default ProjectsList;
