import React from 'react'
import Rodal from 'rodal'
import Select from 'react-select'
import PreviewImage from './previewImage'
import Dropzone from './dropzone'
import API from '../../services/api'
import {toast} from 'react-toastify'
import moment from 'moment'
import MultiSelectDropdown from "../common/multiSelectDropdown";
import Modal from "../common/modal";

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props.entity,
      newFiles: [],
      loading: true,
      deleteModalVisible: false,
    }
  }

  componentDidMount() {
    API.getTags().then(tags => {
      const options = []
      tags.forEach(tag => {
        let option = {}
        option.key = tag.id
        option.label = tag.name

        options.push(option)
      })

      const projectTags = this.state.tags.map(it => {
        return {
          id: it,
          label: tags.find(tag => tag.id === it)?.name || ''
        }
      })
      this.setState({
        ...this.state,
        tagOptions: options,
        loading: false,
        projectTags
      })
    })
  }

  updateField(e, key) {
    this.setState({[key]: e.target.value})
  }

  updateBoolean(value, key) {
    this.setState({[key]: value})
  }

  updateArray(e, key, index) {
    if (key === 'tags') {
      let found = false
      this.state.tags.forEach(tag => {
        if (tag === e.target.value)
          found = true
      })
      if (found)
        return toast.error('Tag already added')
    }

    let tempArray = this.state[key]
    tempArray[index] = e.target.value
    this.setState({[key]: tempArray})
  }

  onCancel(e, callback) {
    e.preventDefault()
    callback()
  }

  onSubmit(e, callback) {
    e.preventDefault()
    callback()
  }

  showImage = (file, previewFile) => {
    // show image preview and store it's actual file in newFiles
    this.setState({
      ...this.state,
      slides: [...this.state.slides, previewFile],
      newFiles: [...this.state.newFiles, file]
    })
  }

  removeImage = (index) => {
    const slides = this.state.slides
    const slide = slides[index]
    let newFiles = this.state.newFiles
    // remove potential new images
    if (slide.temp) {
      const slideName = slides[index].name
      newFiles = newFiles.filter(f => f.name !== slideName)
    }
    // remove any images being previewed currently
    slides.splice(index, 1)
    this.setState({...this.state, slides, newFiles})
  }

  newTag = () => {
    this.setState({...this.state, tags: [...this.state.tags, '']})
  }

  removeTag = (index) => {
    const tags = this.state.tags
    tags.splice(index, 1)
    this.setState({...this.state, tags})
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          (
            <form className="projects" onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
              {/* <div className="actions clearfix">
					<div className="buttons clearfix">
						<div className="button grey" onClick={(e) => this.onCancel(e, () => this.props.cancel())}>Cancel</div>
						<button className="blue">Save</button>
					</div>
				</div> */}

              <div className="block" style={{borderRadius: "40px 40px 0 0"}}>
                <div className='header-window'>
                  <h2>{this.props.new ? 'Create' : 'Edit'} project</h2>
                  <div className="actions under-content">
                    {
                      !this.props.new && (
                        <button type='button' onClick={() => {
                          this.setState({...this.state, deleteModalVisible: true})
                        }} className="delete-button" style={{marginRight: '20px'}}>
                          Delete project
                        </button>
                      )
                    }
                    <button>Save</button>
                  </div>
                </div>

                <div className='scrollable'>
                  <div className='section'>
                    <span>Project information</span>

                    <div className='wrap-inputs col-3'>
                      <div>
                        <label>Client name</label>
                        <input
                          className='field-input'
                          value={this.state.clientName}
                          onChange={(e) => this.updateField(e, 'clientName')}
                        />
                      </div>

                      <div>
                        <label>Project name</label>
                        <input
                          className='field-input'
                          value={this.state.name}
                          onChange={(e) => this.updateField(e, 'name')}
                        />
                      </div>

                      <div>
                        <label>Year</label>
                        <Select
                          className='tag-wrap'
                          classNamePrefix='tag-select'
                          isSearchable={false}
                          value={{label: this.state.year, value: this.state.year}}
                          onChange={(e) => this.setState({year: e.value})}
                          options={Array.from(Array(25)).map((_, i) => ({
                            value: moment().year() - i, label: moment().year() - i,
                          }))}
                        />
                      </div>
                    </div> 
                    <div className='wrap-inputs'>
                        <div style={{maxWidth: "100%"}}>
                          <label>Tags</label>
                          <MultiSelectDropdown
                            clearText={'Select Tags'}
                            allowClear={false}
                            value={this.state.projectTags.map(tag => (tag.id))}
                            onChange={(selectedOptions) => {
                              this.setState({
                                tags: selectedOptions || [],
                                projectTags: selectedOptions, 
                              });
                            }}
                            list={this.state.tagOptions?.map(option => ({
                              name: option.label,
                              id: option.key,
                            })) || []}
                          />
                        </div>
                      </div>
                  </div>

                  <div className='section'>
                    <span>Project slides</span>

                    <Dropzone
                      showImage={this.showImage}
                    />

                    <div className='wrap-inputs col-4'>
                      {this.state.slides.map((slide, index) => (
                        <div
                          key={index}
                          className='image-wrap'
                        >
										<span className='count'>
											{index + 1 < 10 ? '0' : ''}{index + 1}
										</span>
                          <img
                            src={API.apiURL + '/storage/' + slide.uri.split('-')[0] + '/' + slide.uri}
                            alt=""
                          />
                          <span className='name'>
											{slide.name}
                            <i onClick={() => this.removeImage(index)} style={{verticalAlign: 'middle'}}>
                              <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
<path
  d="M1.84984 12C1.57484 12 1.33945 11.9021 1.14367 11.7062C0.947782 11.5104 0.849837 11.275 0.849837 11V1.5H0.166504V0.5H3.29984V0H7.69984V0.5H10.8332V1.5H10.1498V11C10.1498 11.2667 10.0498 11.5 9.84984 11.7C9.64984 11.9 9.4165 12 9.14984 12H1.84984ZM9.14984 1.5H1.84984V11H9.14984V1.5ZM3.6165 9.56667H4.6165V2.91667H3.6165V9.56667ZM6.38317 9.56667H7.38317V2.91667H6.38317V9.56667Z"
  fill="black"/>
</svg>

                            </i>
										</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                visible={this.state.deleteModalVisible}
                onClose={() => this.setState({deleteModalVisible: false})}
                title="Are you sure to delete this project?"
                confirmText="Delete"
                cancelText="Cancel"
                onConfirm={this.props.onDelete}
                onCancel={() => this.setState({deleteModalVisible: false})}
              >
              </Modal>
            </form>
          )
        }
      </>
    )
  }
}


export default Form;