import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import API from '../../services/api.jsx'


function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const [acceptableType, setAcceptableType] = useState('')
  const fileTypeProps = props.fileType

  const {getRootProps, getInputProps} = useDropzone({
    accept: acceptableType,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        ...acceptedFiles,
        preview: URL.createObjectURL(file)
      })));
      API.postAsset(acceptedFiles[0]).then(previewFile => {
        // add temp key to file. Means you can filter temporary images for preview before updating entity
        previewFile.file.temp = true
        props.showImage(acceptedFiles[0], previewFile.file)
      })
    }
  });

  useEffect(() => {
    setAcceptableType('image/*')
  }, [fileTypeProps])


  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (files.length) {
      files.forEach(file => {
        URL.revokeObjectURL(file.preview)
      })
    }
  }, [files]);

  return (
    <>
      <div key={props.key}{...getRootProps({className: 'dropzone image-wrap'})}>
        <input {...getInputProps()} disabled={props.disabled}/>
        <div className="image-icon-wrap">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', top: '10px', right: '16px'}}>
            <path
              d="M5.50002 8.78329V2.59996L3.50002 4.59996L2.78335 3.88329L6.00002 0.666626L9.21669 3.88329L8.50002 4.59996L6.50002 2.59996V8.78329H5.50002ZM1.66669 11.3333C1.40002 11.3333 1.16669 11.2333 0.966687 11.0333C0.766687 10.8333 0.666687 10.6 0.666687 10.3333V7.94996H1.66669V10.3333H10.3334V7.94996H11.3334V10.3333C11.3334 10.6 11.2334 10.8333 11.0334 11.0333C10.8334 11.2333 10.6 11.3333 10.3334 11.3333H1.66669Z"
              fill="#1C1B1F"/>
          </svg>
          <p>Upload image</p>
        </div>
      </div>
    </>
  );
}

export default Dropzone