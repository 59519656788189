import axios from 'axios'

class API {

	// API constructor
	constructor() {
		this.apiURL = process.env.REACT_APP_API_URL
		this.headers = {}
		this.token = null
	}

	setToken(token) {
		this.token = token
	}

	/**
	 * CRUD functions
	 */

	// Get list of entities from given model
	list(model) {
		return this.request('get', model)
	}

	// Create new entity on given model
	create(model, fields) {
		return this.request('post', model, fields)
	}

	// Read entity from the api for a given model and id
	read(model, id) {
		return this.request('get', model + '/' + id)
	}

	// Update an entity on the given model
	update(model, id, fields) {
		return this.request('put', model + '/' + id, fields)
	}

	// Delete an entity
	delete(model, id) {
		return this.request('delete', model + '/' + id, {})
	}

	/**
	 * HTTP request
	 */

	request(method, path, data) {
		return new Promise((resolve, reject) => {
			axios({
				method: method,
				baseURL: this.apiURL,
				url: path,
				data: data ? data : null,
				headers: {
					authorization: 'Bearer ' + this.token,
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
		})
	}

	getProjectsDetails(params, sort) {
		const filter = {
			tags: {
				$all: params.tags
			},
			$or: [
				{
					name: {
						$regex: params.name,
						$options: 'i'
					}
				},
				{
					clientName: {
						$regex: params.name,
						$options: 'i'
					}
				}
			],
			year: params.year
		}
		if (params.tags.length === 0) {
			delete filter.tags
		}
		if (!params.name) {
			delete filter.$or
		}
		if (params.year.length === 0) {
			delete filter.year
		}
		return this.list(`projects/details?filter=${JSON.stringify(filter)}${sort ? '&sort=' + sort : ''}`)
	}

	getPortfolios(params) {
		params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
		return this.list('portfolios' + params)
	}

	getTags(params) {
		params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
		return this.list('tags' + params)
	}

	getProject(id) {
		return this.read('projects', id)
	}

	getTag(id) {
		return this.read('tags', id)
	}

	createPortfolio(fields, pdfType) {
		return new Promise((resolve, reject) => {
			const url = this.apiURL + `/portfolios/return-pdf?token=${this.token}&pdfType=${pdfType}&` + fields.map(id =>  'project=' + id).join('&')
			resolve(url)
		})
	}

	createOrUpdatePortfolio(data, id = null) {
		return new Promise((resolve, reject) => {
			axios({
				baseURL: this.apiURL,
				url: id ? `portfolios/${id}` : 'portfolios',
				data: JSON.stringify(data),
				method: id ? 'put' : 'post',
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + this.token
				}
			})
				.then((response) => {
					resolve(response.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	createOrUpdateProject(data, files, id = null) {
		var formData = new FormData()
		for(const i in files) {
			const file = files[i]
			formData.append('slides', file)
		}
		formData.append('data', JSON.stringify({data}))
		return new Promise((resolve, reject) => {
			axios({
				baseURL: this.apiURL,
				url: id ? `projects/${id}` : 'projects',
				data: formData,
				method: id ? 'put' : 'post',
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: 'Bearer ' + this.token
				}
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
		})
	}

	updateProjectOrder(data, id) {
		var formData = new FormData()
		formData.append('data', JSON.stringify({data}))
		return new Promise((resolve, reject) => {
			axios({
				baseURL: this.apiURL,
				url: `projects/${id}`,
				data: formData,
				method: 'put',
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: 'Bearer ' + this.token
				}
			})
				.then((response) => {
					resolve(response.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	createOrUpdateTag(data, id = null) {
		return new Promise((resolve, reject) => {
			axios({
				baseURL: this.apiURL,
				url: id ? `tags/${id}` : 'tags',
				data: data,
				method: id ? 'put' : 'post',
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + this.token
				}
			})
				.then((response) => {
					resolve(response.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	postAsset(file) {
		var formData = new FormData()
		formData.append('file', file)
		formData.append('data', JSON.stringify({}))
		return new Promise((resolve, reject) => {
			axios({
				baseURL: this.apiURL,
				url: 'assets',
				data: formData,
				method: 'post',
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: 'Bearer ' + this.token
				}
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
		})
	}
}

const API_MODULE = new API();
export default API_MODULE;



